<template>
    <div class="page pd">
        <div class="panel">
            <div class="header">User Guide</div>
            <div class="content">
                <iframe src="https://s3.eu-west-2.amazonaws.com/prod-nr-s3/document/user-guide-v1.4-FINAL.pdf"
                        width="100%"
                        height="800px"/>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: "user-guide",

}
</script>

<style scoped lang="scss">

</style>